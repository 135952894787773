<template>
  <b-card
    no-body
    :class="{ open: visible }"
  >
    <b-card-header
      ref="myCollapsible"
      :class="{ collapsed: visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="collapseOpen(!visible, $event)"
    >
      <slot name="header">
        <span class="lead collapse-title">
          <b-media class="media">
            <div class="nameClass">
              <b-link>
                <span class="d-block font-weight-bolder text-nowrap">
                  {{ title }}
                </span>
              </b-link>
            </div>
          </b-media>
        </span>
        <div
          class="d-flex"
        >
          <div>
            <vs-switch
              :value="state"
              circle
              class="switches"
              disabled
              :style="`background-color: ${state ? '#00E265' : '#FF0000'};color:white;`"
              @click="disabledSwitchFunc($event)"
            >
              <span
                slot="on"
                style="font-weight: bold"
              >Disable</span>
              <span
                slot="off"
                style="font-weight: bold"
              >Enable</span>
            </vs-switch>
          </div>
          <div
            class="ml-1 time"
            @click="timeDiv($event)"
          >
            <b-img
              size="16px"
              :src="require('@/assets/images/simiicons/wall-clock.svg')"
            />
            {{ time }}
          </div>
        </div>
        <div>
          <span>
            <feather-icon
              :id="id"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              style="margin: 8px"
              @click="review($event)"
            />
          </span>
        </div>
      </slot>
    </b-card-header>
    <div v-if="visible">
      <div class="device">
        Devices
      </div>
      <b-row class="mx-0">
        <b-col
          v-for="(data,index) in campaign.banner_campaigns_has_devices"
          :key="index"
          class="flex-grow-0 align-items-left my-1 mx-0"
        >
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                size="32"
                :src="require('@/assets/images/simiicons/device.svg')"
              />
            </template>
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.device.name }}
            </span>
            <small class="text-muted">{{ data.device.mac_address }}</small>
          </b-media>
        </b-col>
      </b-row>
      <!--      devices-group-->
      <div class="device">
        Devices Groups
      </div>
      <b-row class="mx-0">
        <b-col
          v-for="(data,index) in campaign.banner_campaign_has_group"
          :key="index"
          class="flex-grow-0 align-items-left my-1 mx-0"
        >
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                size="32"
                :src="require('@/assets/images/simiicons/device.svg')"
              />
            </template>
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.device_groups.name }}
            </span>
            <small class="text-muted">{{ data.device_groups.mac_address }}</small>
          </b-media>
        </b-col>
      </b-row>
    </div>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body style="padding: 0px !important">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BMedia,
  BLink,
  BImg,
  BRow,
  BCol,
  VBToggle,

} from 'bootstrap-vue'
import { required } from '@validations'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import '@/assets/images/simiicons/edit.svg'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BMedia,
    BLink,
    BImg,
    BRow,
    BCol,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['isVisible', 'title', 'icons', 'icon', 'src', 'time', 'id', 'edit', 'campaign', 'state'],
  data() {
    return {
      disabledButton: false,
      active: false,
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      // validation
      required,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    collapseOpen(val, e) {
      e.preventDefault()
      e.stopPropagation()
      this.visible = val
    },
    collapseClose() {
      if (this.openOnHover) {
        this.visible = false
      }
    },
    timeDiv(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    async review(e) {
      e.preventDefault()
      e.stopPropagation()
      const allMedia = this.campaign.banner_campaign_has_media.map(media => media.media)
      if (allMedia) {
        await this.$store.dispatch('lightBox/lightBoxData', allMedia)
      }
    },
    disabledSwitchFunc(e) {
      e.preventDefault()
      e.stopPropagation()
    },
  },
}
</script>
<style scoped>
.switches {
  border-radius: 25px;
}
.device{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}
.time{
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #414141;
  font-weight: 500;
  font-family: "Montserrat";
}
.time{
  margin-top: 4px;
}
.nameClass{
  min-width: 70px;
}

</style>
